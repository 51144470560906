<template>
  <header
    ref="header"
    class="border-static-default-low sticky top-0 z-10 border-b-1 transition-all duration-200"
    :class="{ '-translate-y-full': headerIsHidden && !isOpen }"
  >
    <div id="burger-header" class="block md:hidden">
      <div
        class="bg-static-default-low flex flex-wrap items-center justify-between gap-16 px-20 py-16 md:pb-0 lg:gap-56 lg:px-32 lg:py-20"
      >
        <MenuIcon class="flex-1" />
        <TheLogo class="h-12" />
        <SearchBar
          class="z-[1] order-last w-full grow lg:order-none lg:flex-1"
        />
        <UserOptions class="flex-1" />
        <NavBurgerMenu v-if="isOpen" />
      </div>
    </div>
    <div id="fullscreen-header" class="bg-static-default-low hidden md:block">
      <RevContainer class="bg-surface-default-hi">
        <TopHeader class="relative z-[2]" />
      </RevContainer>

      <RevContainer class="py-8">
        <div class="flex items-center gap-32">
          <TheLogo class="h-24" />
          <SearchBar class="z-[1] grow" />
          <div class="flex gap-8">
            <TradeInButton v-if="features.buyback.enabled" />
            <div class="flex items-center">
              <UserIcon />
              <CartIcon data-test="icon-cart" />
            </div>
          </div>
        </div>
      </RevContainer>

      <RevContainer>
        <NavMenu class="h-48" />
      </RevContainer>
    </div>
  </header>
</template>

<script setup lang="ts">
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevContainer } from '@ds/components/Container'

import SearchBar from '~/scopes/search/SearchBar/SearchBar.vue'

import { useHideOnScroll } from '../composables/useHideOnScroll'
import { useNavigation } from '../composables/useNavigation'

import CartIcon from './CartIcon.vue'
import MenuIcon from './MenuIcon.vue'
import NavBurgerMenu from './NavBurgerMenu/NavBurgerMenu.vue'
import NavMenu from './NavMenu/NavMenu.vue'
import TheLogo from './TheLogo.vue'
import TopHeader from './TopHeader.vue'
import TradeInButton from './TradeInButton.vue'
import UserIcon from './UserIcon.vue'
import UserOptions from './UserOptions.vue'

const { isOpen } = useNavigation()

const { hidden: headerIsHidden } = useHideOnScroll()

const {
  features,
}: {
  features: {
    buyback: { enabled: boolean }
  }
} = useMarketplace()
</script>
